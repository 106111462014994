import React, { useState, useEffect, useCallback } from 'react';
import { WifiIcon } from 'lucide-react';
import TemperatureChart from './TemperatureChart';  // 新しいTemperatureChartをインポート

const ConnectionStatus = ({ wsStatus }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case '接続中':
        return 'text-green-500';
      case '接続試行中':
        return 'text-yellow-500';
      case '未接続':
        return 'text-red-500';
      default:
        return 'text-gray-500';
    }
  };

  return (
    <div className="bg-white shadow rounded-lg p-4 mb-4">
      <h2 className="text-xl font-semibold mb-3">接続状態</h2>
      <div className="flex items-center mb-2">
        <WifiIcon className={`mr-2 ${getStatusColor(wsStatus)}`} />
        <span className="font-medium">TMS#000: </span>
        <span className={`ml-2 ${getStatusColor(wsStatus)}`}>
          {wsStatus}
          {wsStatus === '接続試行中' && <span className="animate-pulse">...</span>}
        </span>
      </div>
    </div>
  );
};

const MQTTForm = ({ wsStatus, setWsStatus }) => {
  const [socket, setSocket] = useState(null);
  const [newConfig, setNewConfig] = useState({
    upperLimit: '',
    lowerLimit: '',
    csvSaveTime: '',
    dataSendInterval: '',
    signalStrengthThreshold: ''
  });
  const [currentConfig, setCurrentConfig] = useState({
    upperLimit: '',
    lowerLimit: '',
    csvSaveTime: '',
    dataSendInterval: '',
    signalStrengthThreshold: '',
    deviceType: 'None'
  });
  const [loading, setLoading] = useState(false);
  const [lastUpdate, setLastUpdate] = useState('未更新');

  const connectWebSocket = useCallback(() => {
    const wsProtocol = 'wss';
    const wsHost = 'ksk-aircloud.com';
    const wsPort = 443;

    const ws = new WebSocket(`wss://${window.location.host}/ws/mqtt/`);
    ws.onopen = () => {
      console.log('WebSocket Connected');
      setSocket(ws);
      setWsStatus('接続中');
      ws.send(JSON.stringify({ action: 'get_config' }));
    };
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'config_update') {
        const updatedConfig = {
          upperLimit: data.config.上限値?.toString() || '',
          lowerLimit: data.config.下限値?.toString() || '',
          csvSaveTime: data.config.CSV保存時間?.toString() || '',
          dataSendInterval: data.config.データ送信間隔?.toString() || '',
          signalStrengthThreshold: data.config.電波強度閾値?.toString() || '',
          deviceType: data.config.device_type || 'None'
        };
        setCurrentConfig(updatedConfig);
        setNewConfig(updatedConfig);
        setLastUpdate(data.config.device_type || 'Unknown');
        setLoading(false);
      } else if (data.type === 'status_update') {
        setWsStatus(data.ws_status);
      }
    };
    ws.onclose = () => {
      console.log('WebSocket Disconnected');
      setSocket(null);
      setWsStatus('未接続');
    };
    return ws;
  }, [setWsStatus]);

  useEffect(() => {
    const ws = connectWebSocket();
    return () => {
      ws.close();
    };
  }, [connectWebSocket]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewConfig(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (parseInt(newConfig.upperLimit) > 150) {
      alert('上限値は150以内で設定してください');
      setLoading(false);
      return;
    }

    if (parseInt(newConfig.lowerLimit) >= parseInt(newConfig.upperLimit)) {
      alert('下限値 < 上限値 としてください。');
      setLoading(false);
      return;
    }

    const configData = {
      "上限値": parseInt(newConfig.upperLimit) || 0,
      "下限値": parseInt(newConfig.lowerLimit) || 0,
      "CSV保存時間": parseInt(newConfig.csvSaveTime) || 0,
      "データ送信間隔": parseInt(newConfig.dataSendInterval) || 0,
      "電波強度閾値": parseInt(newConfig.signalStrengthThreshold) || 0
    };

    if (socket) {
      socket.send(JSON.stringify({ action: 'send_config', config: configData }));
    } else {
      alert('WebSocket接続がありません。');
      setLoading(false);
    }
  };

  const handleGetCurrentConfig = () => {
    setLoading(true);
    if (socket) {
      socket.send(JSON.stringify({ action: 'get_config' }));
    } else {
      alert('WebSocket接続がありません。');
      setLoading(false);
    }
  };

  return (
    <div className="bg-blue-50 rounded-lg shadow p-4">
      <div className="flex justify-between">
        <div className="w-1/2 pr-4">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-2xl font-semibold text-blue-700">現在の設定</h2>
          </div>
          <div className="bg-white rounded-lg shadow p-4">
            <div className="mb-4">
              <label className="block text-blue-600 text-sm font-bold mb-2">上限値：</label>
              <input type="text" value={currentConfig.upperLimit} readOnly className="w-full p-2 border border-blue-300 rounded-md bg-white text-blue-800" />
            </div>
            <div className="mb-4">
              <label className="block text-blue-600 text-sm font-bold mb-2">下限値：</label>
              <input type="text" value={currentConfig.lowerLimit} readOnly className="w-full p-2 border border-blue-300 rounded-md bg-white text-blue-800" />
            </div>
            <div className="mb-4">
              <label className="block text-blue-600 text-sm font-bold mb-2">CSV保存時間 (秒)：</label>
              <input type="text" value={currentConfig.csvSaveTime} readOnly className="w-full p-2 border border-blue-300 rounded-md bg-white text-blue-800" />
            </div>
            <div className="mb-4">
              <label className="block text-blue-600 text-sm font-bold mb-2">データ送信間隔 (秒)：</label>
              <input type="text" value={currentConfig.dataSendInterval} readOnly className="w-full p-2 border border-blue-300 rounded-md bg-white text-blue-800" />
            </div>
            <div className="mb-4">
              <label className="block text-blue-600 text-sm font-bold mb-2">電波強度閾値 (dBm)：</label>
              <input type="text" value={currentConfig.signalStrengthThreshold} readOnly className="w-full p-2 border border-blue-300 rounded-md bg-white text-blue-800" />
            </div>
            <button
              onClick={handleGetCurrentConfig}
              disabled={loading}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
            >
              現在値を再取得
            </button>
          </div>
        </div>

        <div className="w-1/2 pl-4">
          <h2 className="text-2xl font-semibold text-blue-700 mb-4">新しい設定</h2>
          <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow p-4">
            <div className="mb-4">
              <label htmlFor="upperLimit" className="block text-blue-600 text-sm font-bold mb-2">上限値：</label>
              <input
                type="number"
                id="upperLimit"
                name="upperLimit"
                value={newConfig.upperLimit}
                onChange={handleInputChange}
                className="w-full p-2 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="lowerLimit" className="block text-blue-600 text-sm font-bold mb-2">下限値：</label>
              <input
                type="number"
                id="lowerLimit"
                name="lowerLimit"
                value={newConfig.lowerLimit}
                onChange={handleInputChange}
                className="w-full p-2 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="csvSaveTime" className="block text-blue-600 text-sm font-bold mb-2">CSV保存時間 (秒)：</label>
              <input
                type="number"
                id="csvSaveTime"
                name="csvSaveTime"
                value={newConfig.csvSaveTime}
                onChange={handleInputChange}
                className="w-full p-2 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="dataSendInterval" className="block text-blue-600 text-sm font-bold mb-2">データ送信間隔 (秒)：</label>
              <input
                type="number"
                id="dataSendInterval"
                name="dataSendInterval"
                value={newConfig.dataSendInterval}
                onChange={handleInputChange}
                className="w-full p-2 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                required
              />
            </div>
            <div className="mb-6">
              <label htmlFor="signalStrengthThreshold" className="block text-blue-600 text-sm font-bold mb-2">電波強度閾値 (dBm)：</label>
              <input
                type="number"
                id="signalStrengthThreshold"
                name="signalStrengthThreshold"
                value={newConfig.signalStrengthThreshold}
                onChange={handleInputChange}
                className="w-full p-2 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                required
              />
            </div>
            <button
              type="submit"
              disabled={loading}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
            >
              設定を更新
            </button>
          </form>
        </div>
      </div>
      <div className="text-right mt-4">
      <span className="text-sm text-gray-600">最終更新: {currentConfig.deviceType}</span>
      </div>
    </div>
  );
};

const TMS000 = () => {
  const [wsStatus, setWsStatus] = useState('未接続');

  return (
    <div className="bg-blue-50 min-h-screen p-1 overflow-x-auto">
      <div className="max-w-[95%] mx-auto min-w-[1200px]">
        <h1 className="text-3xl font-bold text-blue-800 mb-8 text-center">TMS#000</h1>
  
        <div className="mb-4">
          <ConnectionStatus wsStatus={wsStatus} />
        </div>
  
        <div className="grid grid-cols-[3fr_1fr] gap-8">
          <div className="flex flex-col h-full flex-grow">
            <div className="bg-white rounded-lg shadow-lg p-6 flex-grow">
              <h2 className="text-2xl font-semibold text-blue-700 mb-4 text-center">TMS#000 Chart</h2>
              <div className="h-full">
                <TemperatureChart />
              </div>
            </div>
          </div>
          <div className="flex-shrink-0 w-[550px]">
            <div className="bg-white rounded-lg shadow-lg p-6 h-full">
              <h2 className="text-2xl font-semibold text-blue-700 mb-4 text-center">TMS#000 Config</h2>
              <MQTTForm wsStatus={wsStatus} setWsStatus={setWsStatus} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TMS000;